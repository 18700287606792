import Config from '@/config';

const actions = {
    /**
     * Fetches group tasks.
     * @param store
     * @param params
     * @returns {*}
     */
    /*tasks(store, params) {
        return store.dispatch('get', {
            action: 'group/tasks',
            params
        }, {root: true});
    },*/

    /**
     * Updates properties of group.
     * params: {
     *  groupAuth,
     *  params: {props to update}
     * }
     * @param store
     * @param params
     * @returns {*}
     */
    /*update(store, params) {
        console.log("ACTION group/update", params);

        // local change
        store.commit("groupUpdate", params, {root: true});

        return store.dispatch('post', {
            action: 'group/update',
            params,
        }, {root: true});
    },*/
    /**
     * User level.
     * @param store
     * @param params
     * @returns {*}
     */
    fav(store, params) {
        console.log("ACTION group/fav", params);

        // local change
        store.commit("groupFav", params, {root: true});

        return store.dispatch('post', {
            action: 'group/fav',
            params,
        }, {root: true});
    },
    /**
     * User level.
     * @param store
     * @param params
     * @returns {*}
     */
    /*archive(store, params) {
        console.log("ACTION group/archive", params);

        // local change
        store.commit("groupArchive", params, {root: true});

        return store.dispatch('post', {
            action: 'group/archive',
            params,
        }, {root: true});
    },*/

    /**
     * Sorts tasks in current context: group or topic.
     */
    taskSort(store, params) {
        console.log("ACTION group/taskSort", params);

        // local change
        store.commit("groupTaskSort", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'group/taskSort',
            params,
        }, {root: true});
    },

    /**
     * Sorts topic in a group.
     * @param store
     * @param params
     * @returns {*}
     */
    topicSort(store, params) {
        console.log("ACTION group/topicSort", params);

        // local change
        store.commit("groupTopicSort", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'group/topicSort',
            params
        }, {root: true});
    },

    /**
     * Changes order of groups. User level operation.
     * @param store
     * @param params
     * @returns {*}
     */
    sort(store, params) {
        console.log("ACTION group/sort", params);

        // local change
        store.commit("groupSort", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'group/sort',
            params
        }, {root: true});
    },

    assign(store, params) {
        console.log("ACTION group/assign", params);

        // local change
        store.commit("groupAssign", params, {root: true});

        // remote change
        return store.dispatch('post', {
            action: 'group/assign',
            params
        }, {root: true});
    },

    /**
     * Displays group menu.
     * @param store
     * @param params
     * @returns {*}
     */
    menu(store, params) {
        return store.commit('uiItemMenu', {
            menu: store.rootState.ui.groupMenu,
            params
        }, {root: true});
    },

    /**
     * Displays group selector.
     * @param store
     * @param params
     * @returns {Promise<unknown>}
     */
    selector(store, params) {
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiItemMenu', {
                menu: store.rootState.ui.groupSelector,
                params
            }, {root: true});
        });
    },

    // TODO REVIEW

    /**
     * Fetches group item.
     * @depricated
     * @param store
     * @param params
     * @returns {*}
     */
    item(store, params) {
        return store.dispatch('get', {
            action: 'group-item',
            params
        }, {root: true});
    },

    refetch(store, params) {
        return store.dispatch('get', {
            action: 'group-item', params: {
                auth: store.rootState.page?.group?.group?.auth,
                topicAuth: store.rootState.page?.group?.topic?.auth,
                tab: store.rootState.page?.group?.tab?.id,
                assigneeAuth: store.rootState.page?.group?.assignee?.auth,
                prio: store.rootState.page?.group?.prio,
                search: store.rootState.ui.search.value,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },

    del(store, params) {
        return store.dispatch('post', {
            ...params, action: 'group-delete',
        }, {root: true});
    },
};

export default actions;
