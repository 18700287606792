
export default {
    isTaskFullscreen: false,
    withMobileGroups: false,
    withMessageBlockForced: false,
    isMsgEditorExpanded: false,
    isTaskEditorExpanded: false,
    isContext1TaskMode: false,
    shouldSortTasks: true,
    isInited: false,

    error: {
        isVisible: false,
        title: null,
        message: null,
        code: null,
    },

    search: {
        value: null,
        isSearching: false,
    },

    taskMenu: {
        isVisible: false,
        item: null,
        callback: null,
        x: 0,
        y: 0,
    },

    taskPrioMenu: {
        isVisible: false,
        item: null,
        callback: null,
        x: 0,
        y: 0,
    },

    groupMenu: {
        isVisible: false,
        item: null,
        callback: null,
        x: 0,
        y: 0,
    },

    assigneeMenu: {
        isVisible: false,
        item: null,
        user: null,
        callback: null,
        x: 0,
        y: 0,
        users: null,
        usersOfGroup: null,
        exclude: null,
        isUnpreffed: false,
    },

    groupSelector: {
        isVisible: false,
        item: null,
        items: null,
        callback: null,
        x: 0,
        y: 0,
    },

    topicSelector: {
        isVisible: false,
        item: null,
        items: null,
        callback: null,
        x: 0,
        y: 0,
    },

    deadlineSelector: {
        isVisible: false,
        item: null,
        items: null,
        callback: null,
        x: 0,
        y: 0,
    },

    tagSelector: {
        isVisible: false,
        items: null,
        value: null,
        callback: null,
        x: 0,
        y: 0,
    },

    dialogCalendar: {
        isVisible: false,
        item: null,
        title: null,
        prop: null,
        clearLabel: null,
        callback: null,
    },

    dialogSelect: {
        isVisible: false,
        title: null,
        item: null,
        prop: null,
        options: null,
        callback: null,
    },

    dialogConfirm: {
        isVisible: false,
        isLoading: false,
        title: null,
        message: null,
        callback: null,
    },

    dialogTaskDelay: {
        isVisible: false,
        item: null,
    },

    dialogTaskMove: {
        isVisible: false,
        item: null,
    },

    dialogTaskTemplate: {
        isVisible: false,
        item: null,
    },

    dialogUserEdit: {
        isVisible: false,
        item: null,
        callback: null,
    },

    dialogFilesViewer: {
        isVisible: false,
        files: null,
        index: null,
    },

    dialogContext: {
        isVisible: false,
        isLoading: false,
        item: null,
        callback: null,
    },

    newTaskEditor: {
        text: null,
    },

    pending: {
        area: null,
        tab: null,
    },

    mutations: {
        groupTaskSort: {
            timeout: null,
            userAuth: null,
        },
        taskGroup: {
            timeout: null,
            userAuth: null,
        },
        group: {
            timeout: null,
            subjectAuth: null,
            userAuth: null,
            icon: null,
        },
        task: {
            timeout: null,
            subjectAuth: null,
            userAuth: null,
            icon: null,
        },
    },
}
