import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";
import {date2Iso, iso2Date} from "@/utils/date";
import {findPs} from "@/utils/p";

const mutations = {
    userSession: (state, payload) => {
        console.log("MUTATION userSession", payload);
        if (!payload) return;

        const area = state.session.area;

        // resort JSON-encoded data
        const user = payload.user;
        if (user) {
            if (typeof user.taskSort === "string") user.taskSort = JSON.parse(user.taskSort);
            if (typeof user.groupSort === "string") user.groupSort = JSON.parse(user.groupSort);
            sortByAuth(area.groups, user.groupSort);
        }

        // apply isArchived to groups/topics
        const now = new Date().valueOf();
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const applyIsArchived = (el, isTopic) => {
            let isArchived = false;
            const updatedAt = iso2Date(el.updatedAt);
            const archivedAt = iso2Date(el.archivedAt);
            let activityAt = iso2Date(el.activityAt);

            // todo make same logic for group - do not update group on background
            if ( isTopic && (!activityAt || updatedAt > activityAt) ) activityAt = updatedAt;

            //if (el.id === 58) console.log("Archived? " + el.name + ": " + archivedAt + " <> " + activityAt);
            //console.log("Archived? " + el.id + ": " + archivedAt + " <> " + activityAt);
            if (archivedAt && activityAt && archivedAt > activityAt) isArchived = true;
            else if (archivedAt && !activityAt) isArchived = true;
            else if (activityAt) {
                //if ( el.auth === "xVy2VQbLPV01bs6d" ) console.log("activityAt of "+el.name+": "+activityAt);
                const d = activityAt.valueOf();
                const days = Math.floor((now - d) / millisecondsPerDay);
                isArchived = days >= Config.GROUP_AUTO_ARCHIVED_AFTER_DAYS;
            }
            if (!isArchived) Vue.set(el, "archivedAt", null);
            Vue.set(el, "_isArchived", isArchived);
        };
        area.groups?.forEach(el => applyIsArchived(el));
        area.topics?.forEach(el => applyIsArchived(el, true));

        // sort topics, set topics as children of groups
        if (area.topics?.length) {
            area.groups?.forEach(group => {
                if (typeof group.topicSort === "string") group.topicSort = JSON.parse(group.topicSort);

                const topics = area.topics?.filter(topic => topic.groupId === group.id);
                sortByAuth(topics, group.topicSort);
                const tags = area.tags?.filter(tag => tag.groupId === group.id && !tag.topicId);
                const topicsAndTags = [...(topics||[]), ...(tags||[])];
                Vue.set(group, "children", topicsAndTags);
                topicsAndTags.forEach(el=>Vue.set(el, "parent", group));
                //Vue.set(group, "isGroup", true);
                Vue.set(group, "withArchivedTopics", true);

                //console.log("TOPICS of "+group.name, topics.map(el=>el.auth+":"+el._isArchived));
            });
            area.topics?.forEach(topic => {
                const tags = area.tags?.filter(tag => tag.topicId === topic.id);
                if ( tags?.length ) {
                    sortByAuth(tags, topic.tagSort);
                    Vue.set(topic, "children", tags);
                    //console.log("TAGS of "+topic.name, tags.map(el=>el.id+":"+el.name));
                }
            });
            area.tags?.forEach(tag => {
                Vue.set(tag, "isTag", true);
            });
            //console.log("TAGS", area.tags);
            //console.log("TAGS of "+group.name, topics.map(el=>el.auth+":"+el._isArchived));
        }

        // set groups as children of contexts
        if (area.contexts?.length) {
            area.contexts?.forEach(context => {
                if ( !context.groupIds ) return;

                const groups = area.groups?.filter(group => context.groupIds.includes(group.id));
                Vue.set(context, "children", groups);
                groups.forEach(el=>Vue.set(el, "parent", context));
                //sortByAuth(groups, context.groupSort);

                //console.log("GROUPS of "+context.name, groups.map(el=>el.auth+":"+el.name));
            });
        }

        state.session.isAnon = user?.auth === Config.ANONYMOUS_USER_AUTH;
        //console.log("USER IS ANON: ", user?.auth === Config.ANONYMOUS_USER_AUTH);

        //console.log("1 groups with archived topics", area.groups?.filter(el=>el.children?.find(child=>child._isArchived)).map(el=>el.auth));

        // need small pause to set Vue.set()
        //setTimeout(()=>state.session.isInited = true, 150);
        state.session.isInited = true;

    },

    userTaskSort: (state, payload) => {
        //console.log("MUTATION user/taskSort", payload);

        const user = state.session.user;
        if (!user) return;

        // apply to tasks
        const tasks = state.page.tasks.items;
        sortByAuth(tasks, payload.taskSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        // apply to user
        Vue.set(user, "taskSort", user.taskSort);
        //console.log("user.taskSort", user.taskSort);
    },

    userOnline: (state, payload) => {
        console.log("MUTATION userOnline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const exists = onlineUsers?.find(el => el.auth === payload.userAuth);
        if (!exists) {
            const user = state.session.area.users.find(el => el.auth === payload.userAuth);
            if (user) onlineUsers.push(user);
        }
    },

    userOffline: (state, payload) => {
        console.log("MUTATION userOffline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const index = onlineUsers?.findIndex(el => el.auth === payload.userAuth);
        if (index >= 0) onlineUsers.splice(index, 1);
    },

    userFav: (state, payload) => {
        console.log("MUTATION userFav", payload);
        if (!payload?.userAuth) return;

        const user = state.session.area.users?.find(el => el.auth === payload.userAuth);
        if (user) {
            Vue.set(user, "isFav", payload.isFav);
            Vue.set(user, "updatedAt", date2Iso(new Date()));
        }
    },

    userFavUserSort: (state, payload) => {
        console.log("MUTATION user/favUserSort", payload);

        const user = state.session.user;
        if (!user) return;

        // apply to users
        const users = state.session.area.users;
        sortByAuth(users, payload.favUserSort);

        // apply to user
        Vue.set(user, "userSort", user.favUserSort);
        //console.log("user.taskSort", user.taskSort);
    },

    userUser: (state, payload) => {
        console.log("MUTATION user/user", payload);

        if (!payload?.updatedUser?.auth) return;

        const auth = payload.updatedUser.auth;

        const update = (item) => {
            Object.keys(payload.updatedUser).forEach(key => {
                Vue.set(item, key, payload.updatedUser[key]);
            })
        }

        // update current item
        const user = state.session.area.users?.find(el => el.auth === auth);
        //console.log("USER FOUND", user);
        if (user) update(user);
        if (state.page.context.assignee) update(state.page.context.assignee);
    },
};

export default mutations;
