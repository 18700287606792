import Config from '@/config';
import Vue from "vue";

const actions = {
    confirm(store, params) {
        return new Promise((resolve, reject)=>{
            store.rootState.ui.dialogConfirm.title = params?.title;
            store.rootState.ui.dialogConfirm.message = params?.message;
            store.rootState.ui.dialogConfirm.ok = params?.ok;
            store.rootState.ui.dialogConfirm.callback = (res)=>{
                return resolve(res);
            };
            store.rootState.ui.dialogConfirm.isVisible = true;
        });
    },
    dialogContext(store, params) {
        return new Promise((resolve, reject) => {
            console.log("dialogContext", params);
            store.rootState.ui.dialogContext.item = params?.item;
            store.rootState.ui.dialogContext.callback = (res) => resolve(res);
            store.rootState.ui.dialogContext.isVisible = true;
        });
    },
    deadlineSelector(store, params) {
        console.log("deadlineSelector");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiItemMenu', {
                menu: store.rootState.ui.deadlineSelector,
                params
            }, {root: true});
        });
    },
    tagSelector(store, params) {
        console.log("tagSelector");
        return new Promise((resolve, reject) => {
            params.callback = (res) => resolve(res);
            store.commit('uiItemMenu', {
                menu: store.rootState.ui.tagSelector,
                params
            }, {root: true});
        });
    },
};

export default actions;
